.widget-capacity {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    color: #fff;
    padding: 0 24px;
    .capacity-text {
        color: rgba(255, 255, 255, 0.65);
    }
}

@primary-color: #215891;