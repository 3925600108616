.page-user-detail {
  .handles {
    flex-wrap: wrap;
    &-mobile {
      button {
        margin-bottom: 10px;
      }
    }
  }
}

@primary-color: #215891;