.page-preference-index {

  .content-card {
    background: white;
    border: 1px solid #eee;
    border-radius: 6px;
    padding: 15px;
  }

  .img-logo {
    max-height: 100px;
    cursor: pointer;

  }

  .img-favicon {
    max-height: 30px;
    cursor: pointer;
  }

  .basic-info,
  .preview-info,
  .scan-info {
    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      .title {
        font-size: 22px;
        margin: 0;
        line-height: 50px;
      }
    }
  }

}

@primary-color: #215891;